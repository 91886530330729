import React, { useEffect, useRef, useState } from 'react';
import { Header, Spinner } from 'common';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getSamplePlansTrackingPage } from 'store/samplePlans/thunks';
import {
  ALL_ASSIGNMENTS,
  ALL_READINESS,
  ALL_STATUSES,
  CREATED,
  PLAN_TRACKING_FILTERS,
} from 'constants/samplePlanning';
import { OPERATION } from 'constants/propTypes/operation';
import { AGENCY } from 'constants/products';
import { setLocalStorage } from 'util/localStorage';
import { ColumnSortType, OperationAgencyListType } from 'store/samplePlans/types';
import TrackingTable from './TrackingTable';
import styles from './Container.module.css';
import TrackingFilters from './TrackingFilters';
import TrackingMap from './TrackingMap';
import mapboxgl from 'mapbox-gl';
import { UserSelectorType } from 'store/user/types';
import { ENGLISH } from 'constants/languages';
import { BR, US } from 'constants/countries';
import { ALL_ALERTS, ALL_PLANS } from 'constants/alerts';
import { receiveSamplePlansTracking } from 'store/samplePlans/actions';

const SampleTrackingContainer = () => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef(null);
  const drawRef = useRef<any>(null);

  const [sortingCol, setSortingCol] = useState<ColumnSortType>({
    col: undefined,
    asc: false,
  });
  const [isDisplayMap, setIsDisplayMap] = useState(false);
  const [page, setPage] = useState(1);
  const [showAllAccounts, setShowAllAccounts] = useState(true);
  const [status, setStatus] = useState(ALL_STATUSES);
  const [assignment, setAssignment] = useState(ALL_ASSIGNMENTS);
  const [readiness, setReadiness] = useState(ALL_READINESS);
  const [alert, setAlert] = useState(ALL_ALERTS);
  const [proSelection, setProSelection] = useState(ALL_PLANS);
  const [selectedCountry, setSelectedCountry] = useState<string>(language === ENGLISH ? US : BR);
  const [currentMapBounds, setCurrentMapBounds] = useState<number[][] | null>(null);
  const [operationOrAgency, setOperationOrAgency] = useState<OperationAgencyListType | undefined>(
    undefined,
  );
  const [samplerUser, setSamplerUser] = useState<UserSelectorType | undefined>(undefined);
  const [scannerUser, setScannerUser] = useState<UserSelectorType | undefined>(undefined);
  const [searchObj, setSearchObj] = useState({
    showAllAccounts,
    status,
    assignment,
    readiness,
    alert,
    operationOrAgency,
    samplerUser,
    scannerUser,
    page,
    sortingCol,
    selectedCountry,
    currentMapBounds,
    proSelection,
  });
  const [initSearch, toggleInitSearch] = useState(false);

  const { isFetchingTracking, isLastPage, samplePlans, alertNames } = useSelector(
    (state: RootState) => ({
      samplePlans: state.samplePlans.summary.items,
      isFetchingTracking: state.samplePlans.isFetchingTracking,
      isLastPage: state.samplePlans.summary.isLastPage,
      alertNames: state.user.alertNames,
    }),
  );

  useEffect(() => {
    const filters = localStorage.getItem(PLAN_TRACKING_FILTERS);
    if (filters && alertNames.length) {
      const parsedFilters = JSON.parse(filters);
      setSortingCol(parsedFilters?.sortingCol || { col: undefined, asc: false });
      setShowAllAccounts(parsedFilters?.showAllAccounts);
      setStatus(parsedFilters?.status || ALL_STATUSES);
      setAssignment(parsedFilters?.assignment || ALL_ASSIGNMENTS);
      setReadiness(parsedFilters?.readinessIndex || ALL_READINESS);
      setAlert(parsedFilters?.alert || ALL_ALERTS);
      setOperationOrAgency(parsedFilters?.operationOrAgency);
      setSamplerUser(parsedFilters?.samplerUser);
      setScannerUser(parsedFilters?.scannerUser);
      setSelectedCountry(parsedFilters?.selectedCountry ? parsedFilters.selectedCountry : US);
      setProSelection(parsedFilters?.proSelection || ALL_PLANS);
      setPage(parsedFilters?.page || 1);
      toggleInitSearch(true);
    } else if (alertNames.length) {
      dispatch(getSamplePlansTrackingPage(page, true));
    }
  }, [alertNames]);

  useEffect(() => {
    if (
      initSearch ||
      searchObj.page !== page ||
      searchObj.showAllAccounts !== showAllAccounts ||
      searchObj.status !== status ||
      searchObj.assignment !== assignment ||
      searchObj.readiness !== readiness ||
      searchObj.alert !== alert ||
      searchObj.operationOrAgency !== operationOrAgency ||
      searchObj.proSelection !== proSelection ||
      searchObj.samplerUser !== samplerUser ||
      searchObj.scannerUser !== scannerUser ||
      searchObj.sortingCol !== sortingCol ||
      searchObj.selectedCountry !== selectedCountry ||
      searchObj.currentMapBounds !== currentMapBounds
    ) {
      toggleInitSearch(false);
      dispatch(receiveSamplePlansTracking([], page));
      dispatch(
        getSamplePlansTrackingPage(
          searchObj.page === page ? 1 : page,
          showAllAccounts,
          operationOrAgency?.type === OPERATION ? operationOrAgency?.opAgId : undefined,
          operationOrAgency?.type === AGENCY ? operationOrAgency?.opAgId : undefined,
          Number(samplerUser?.id) || undefined,
          Number(scannerUser?.id) || undefined,
          status === ALL_STATUSES ? undefined : status,
          assignment === ALL_ASSIGNMENTS ? undefined : assignment,
          readiness === ALL_READINESS ? undefined : readiness,
          alert === ALL_ALERTS ? undefined : alert,
          proSelection === ALL_PLANS ? undefined : proSelection,
          selectedCountry,
          currentMapBounds,
          sortingCol,
        ),
      );
      setSearchObj({
        showAllAccounts,
        status,
        assignment,
        readiness,
        alert,
        operationOrAgency,
        proSelection,
        samplerUser,
        scannerUser,
        page,
        sortingCol,
        selectedCountry,
        currentMapBounds,
      });
      setLocalStorage(
        PLAN_TRACKING_FILTERS,
        JSON.stringify({
          showAllAccounts,
          status,
          assignment,
          readiness,
          proSelection,
          alert,
          operationOrAgency,
          samplerUser,
          scannerUser,
          selectedCountry,
          sortingCol,
        }),
      );
    }
  }, [
    initSearch,
    page,
    showAllAccounts,
    searchObj,
    status,
    assignment,
    proSelection,
    readiness,
    alert,
    operationOrAgency,
    samplerUser,
    scannerUser,
    sortingCol,
    selectedCountry,
    currentMapBounds,
  ]);

  const setStatusToCreated = (str: string) => {
    if (str !== ALL_ASSIGNMENTS) {
      setStatus(CREATED);
    }
  };

  const updateReadiness = (str: string) => {
    setStatusToCreated(str);
    setReadiness(str);
  };

  const updateAssignment = (str: string) => {
    setStatusToCreated(str);
    setAssignment(str);
  };

  if (!alertNames.length) {
    return <Spinner className={styles.Spinner} />;
  }

  return (
    <div>
      <Header title={getString('samplePlanTracking', language)} />
      <TrackingFilters
        operationOrAgency={operationOrAgency}
        showAllAccounts={showAllAccounts}
        setShowAllAccounts={setShowAllAccounts}
        setSamplerUser={setSamplerUser}
        setScannerUser={setScannerUser}
        samplerUser={samplerUser}
        scannerUser={scannerUser}
        setOperationOrAgency={setOperationOrAgency}
        setAlert={setAlert}
        setAssignment={updateAssignment}
        setReadiness={updateReadiness}
        setStatus={setStatus}
        setProSelection={setProSelection}
        proSelection={proSelection}
        status={status}
        assignment={assignment}
        readiness={readiness}
        alert={alert}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        setIsDisplayMap={setIsDisplayMap}
        isDisplayMap={isDisplayMap}
        samplingPlanLength={Object.values(samplePlans).flatMap((samplePlan) => samplePlan).length}
      />
      {isDisplayMap ? (
        <TrackingMap
          mapContainerRef={mapContainerRef}
          mapRef={mapRef}
          drawRef={drawRef}
          setMapSearchBounds={setCurrentMapBounds}
          currentMapBounds={currentMapBounds}
          toggleInitSearch={toggleInitSearch}
        />
      ) : (
        <>
          <TrackingTable
            toggleInitSearch={toggleInitSearch}
            sortingCol={sortingCol}
            setSortingCol={setSortingCol}
            setPage={setPage}
          />
          <div className={styles.PaginationWrapper}>
            {isFetchingTracking && Object.keys(samplePlans).length > 0 && (
              <Spinner className={styles.SmallerSpinner} />
            )}
            {isLastPage && !isFetchingTracking && getString('endOfResults', language)}
          </div>
        </>
      )}
    </div>
  );
};

export default SampleTrackingContainer;
