import React, { useState, useContext, createContext, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContextType } from 'util/generalTypes';
import { RootState } from 'store';
import {
  userIsAdmin as userIsAdminSelector,
  userIsAgencyAdmin as userIsAgencyAdminSelector,
  userIsAgent as userIsAgentSelector,
  userIsLab as userIsLabSelector,
  userIsOperator as userIsOperatorSelector,
  userIsSampler as userIsSamplerSelector,
  userIsSuperAdmin as userIsSuperAdminSelector,
} from 'store/user/selectors';
import setToast from 'actions/toastActions';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import useFirebase from 'util/hooks/useFirebase';
import AuthStore from 'AuthStore';
import { LOADING_FETCHES } from 'constants/auth';

const AuthContext = createContext<AuthContextType>(null!);

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const firebase = useFirebase();
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [fetchMetaStatus, setFetchMetaStatus] = useState<string>(LOADING_FETCHES.NOT_STARTED);

  const { user, isSuperAdmin, isAdmin, isAgencyAdmin, isAgent, isSampler, isLab, isOperator } =
    useSelector((state: RootState) => ({
      user: state.user.currentUser,
      isSuperAdmin: userIsSuperAdminSelector(state),
      isAdmin: userIsAdminSelector(state),
      isAgencyAdmin: userIsAgencyAdminSelector(state),
      isAgent: userIsAgentSelector(state),
      isOperator: userIsOperatorSelector(state),
      isSampler: userIsSamplerSelector(state),
      isLab: userIsLabSelector(state),
    }));

  const setToastMessage = (message, type?, timeout?) => dispatch(setToast(message, type, timeout));

  const login = async (data: { email: string; password: string }, handleError: Function) => {
    const newRoute = await AuthStore.login(data, dispatch, handleError, firebase);
    if (newRoute) {
      AuthStore.setLoggedIn(true);
      navigate(newRoute);
    }
  };

  const loginWithToken = async (data: { email: string; code: string }, handleError: Function) => {
    const newRoute = await AuthStore.loginWithToken(data, dispatch, handleError, firebase);
    if (newRoute) {
      AuthStore.setLoggedIn(true);
      navigate(newRoute);
    }
  };

  const logout = (translatedMsg?: string) =>
    AuthStore.logout(
      () => translatedMsg && setToastMessage(getString(translatedMsg, language), 'error'),
    );

  const refresh = (translatedMsg: string) =>
    AuthStore.refresh(() => setToastMessage(getString(translatedMsg, language), 'error'));

  const redirectToLogin = () => {
    if (!location.pathname.startsWith('/auth') && !location.pathname.startsWith('/logout')) {
      // Use window instead of navigate to force a reroute
      window.location.href = `/auth/login/?referrer=${window.location.pathname}`;
    }
    AuthStore.setLoggedIn(false);
  };

  const value = {
    ...AuthStore,
    user,
    login,
    loginWithToken,
    logout,
    refresh,
    redirectToLogin,
    loadingAuth,
    setLoadingAuth,
    fetchMetaStatus,
    setFetchMetaStatus,
    isAdmin,
    isSuperAdmin,
    isAgencyAdmin,
    isAgent,
    isSampler,
    isOperator,
    isLab,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
