import React, { useEffect, useState } from 'react';
import { Box, Slider } from '@mantine/core';
import { EOInferenceLayerType } from 'store/eoCollections/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';

type Props = {
  setGeojsonUri: React.Dispatch<React.SetStateAction<string>>;
  availableLayers: EOInferenceLayerType[];
  onChange: () => void;
  setInferenceLayer: (inferenceLayer: EOInferenceLayerType) => void;
};

const DEFAULT_DEPTH = 12;
const DEFAULT_PSI = 300;

const getLayerByDepthAndPsi = (
  depth: number,
  psi: number,
  layers: EOInferenceLayerType[],
): EOInferenceLayerType | undefined => {
  const layerNameToCheck = `rx_${psi}_max_${depth}in_polys`;
  const matchedLayer = layers.find(({ layer_name }) => layer_name === layerNameToCheck);

  return matchedLayer;
};

export const RxResultsSlider = ({
  availableLayers,
  setGeojsonUri,
  onChange,
  setInferenceLayer,
}: Props) => {
  const [depth, setDepth] = useState(DEFAULT_DEPTH);
  const [psi, setPsi] = useState(DEFAULT_PSI);
  const language = useBroswerLanguage();

  useEffect(() => {
    const matchedLayer = getLayerByDepthAndPsi(depth, psi, availableLayers);

    if (!matchedLayer) {
      return;
    }

    // TODO: when fixing blank layer issue on load, try to rm geojsonuri and just use inferenceLayer
    setGeojsonUri(matchedLayer.geojson_uri);
    setInferenceLayer(matchedLayer);
  }, [depth, psi, setGeojsonUri, availableLayers, setInferenceLayer]);

  return (
    <Box
      display="grid"
      pr="lg"
      style={{
        gridTemplateColumns: 'auto 1fr',
        gap: 'var(--mantine-spacing-xl)',
      }}
    >
      <b id="depth-slider" style={{ justifySelf: 'flex-end' }}>
        {getString('depth', language)}
      </b>
      <Slider
        aria-labelledby="depth-slider"
        min={0}
        max={18}
        value={depth}
        step={1}
        thumbSize={24}
        label={(inch) => `${inch}"`}
        style={{ flex: 1 }}
        marks={[
          { value: 0, label: getString('surface', language) },
          { value: 3, label: '3"' },
          { value: 6, label: '6"' },
          { value: 9, label: '9"' },
          { value: 12, label: '12"' },
          { value: 16, label: '16"' },
          { value: 18, label: '18"' },
        ]}
        onChange={(newDepth) => {
          onChange();
          setDepth(newDepth);
        }}
      />
      <b id="psi-slider" style={{ justifySelf: 'flex-end' }}>
        {getString('maxPsi', language)}
      </b>
      <Slider
        aria-labelledby="psi-slider"
        min={0}
        max={1000}
        value={psi}
        step={50}
        thumbSize={24}
        style={{ flex: 1 }}
        marks={[
          { value: 0, label: 0 },
          { value: 250, label: 250 },
          { value: 500, label: 500 },
          { value: 750, label: 750 },
          { value: 1000, label: 1000 },
        ]}
        onChange={(newPsi) => {
          onChange();
          setPsi(newPsi);
        }}
      />
    </Box>
  );
};
