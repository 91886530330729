import React, { useCallback, useEffect } from 'react';
import { Table, Grid, Text, Stack } from '@mantine/core';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { ColumnSortType, SamplePlanTrackingType } from 'store/samplePlans/types';
import styles from './Container.module.css';
import TrackingRow from './TrackingRow';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { FONT_WEIGHT_BOLD } from 'constants/mantine';

type SamplePlanTablePropsType = {
  toggleInitSearch: (val: boolean) => void;
  sortingCol: ColumnSortType;
  setSortingCol: (val: ColumnSortType) => void;
  setPage: (val: (prev: number) => number) => void;
};

const TrackingTable = ({
  toggleInitSearch,
  sortingCol,
  setSortingCol,
  setPage,
}: SamplePlanTablePropsType) => {
  const language = useBroswerLanguage();

  const { isFetchingTracking, samplePlans, isLastPage } = useSelector((state: RootState) => ({
    samplePlans: state.samplePlans.summary.items,
    isFetchingTracking: state.samplePlans.isFetchingTracking,
    isLastPage: state.samplePlans.summary.isLastPage,
  }));

  const handleScroll = useCallback(() => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;

    if (bottom && !isLastPage && !isFetchingTracking) {
      setPage((prev: number) => prev + 1);
    }
  }, [isLastPage, isFetchingTracking]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const sortColumn = (column: string, columnComponent: React.ReactNode) => {
    const clickColumnSort = () => {
      setSortingCol(
        sortingCol.col === column && sortingCol.asc
          ? { col: undefined, asc: false }
          : {
              col: column,
              asc: sortingCol.col === column ? !sortingCol.asc : false,
            },
      );
    };

    return (
      <Grid onClick={clickColumnSort} justify="center" align="center">
        <Grid.Col span={9}>{columnComponent}</Grid.Col>
        <Grid.Col span={3}>
          {!sortingCol.col && <FiArrowDown color="#a7a7a7" />}
          {sortingCol.col === column && sortingCol.asc && <FiArrowUp color="#113063" />}
          {sortingCol.col === column && !sortingCol.asc && <FiArrowDown color="#113063" />}
        </Grid.Col>
      </Grid>
    );
  };

  return (
    <Table
      style={(theme) => ({
        fontSize: theme.fontSizes.xs,
      })}
    >
      <Table.Thead>
        <Table.Tr>
          {['operation', 'field'].map((headerText) => (
            <Table.Th key={headerText} className={styles.SortableColStr}>
              {sortColumn(
                headerText,
                <Text size="xs" fw={FONT_WEIGHT_BOLD}>
                  {getString(headerText, language)}
                </Text>,
              )}
            </Table.Th>
          ))}
          {['planDetails', 'agency'].map((headerText) => (
            <Table.Th key={headerText}>
              <Text size="xs" fw={FONT_WEIGHT_BOLD}>
                {getString(headerText, language)}
              </Text>
            </Table.Th>
          ))}
          <Table.Th className={styles.SortableColStr}>
            <Stack gap={0}>
              {['created', 'updated'].map((headerText) => (
                <>
                  {sortColumn(
                    `${headerText}_at`,
                    <Text key={headerText} size="xs" fw={FONT_WEIGHT_BOLD}>
                      {getString(headerText, language)}
                    </Text>,
                  )}
                </>
              ))}
            </Stack>
          </Table.Th>
          <Table.Th>
            <Text size="xs" fw={FONT_WEIGHT_BOLD}>
              {getString('planStatus', language)}
            </Text>
          </Table.Th>
          <Table.Th className={styles.SortableColStr}>
            <Stack gap={0}>
              {['sampler', 'scanner'].map((headerText) => (
                <>
                  {sortColumn(
                    headerText,
                    <Text key={headerText} size="xs" fw={FONT_WEIGHT_BOLD}>
                      {getString(headerText, language)}
                    </Text>,
                  )}
                </>
              ))}
              <Text size="xs" fw={FONT_WEIGHT_BOLD} className={styles.SpanInDiv}>
                {getString('readiness', language)}
              </Text>
            </Stack>
          </Table.Th>
          <Table.Th>
            <Text size="xs" fw={FONT_WEIGHT_BOLD}>
              {getString('alerts', language)}
            </Text>
          </Table.Th>
          <Table.Th className={styles.SortableColStr}>
            {sortColumn(
              'days_marked_ready',
              <Text size="xs" fw={FONT_WEIGHT_BOLD}>
                {getString('days_marked_ready', language)}
              </Text>,
            )}
          </Table.Th>
          <Table.Th>
            <Stack gap={0}>
              {['sampled', 'arrived', 'completed', 'scan'].map((headerText) => (
                <Text key={headerText} size="xs" fw={FONT_WEIGHT_BOLD} className={styles.SpanInDiv}>
                  {getString(headerText, language)}
                </Text>
              ))}
            </Stack>
          </Table.Th>
          <Table.Th />
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {Object.values(samplePlans).map((plans: SamplePlanTrackingType[]) =>
          plans.map((plan: SamplePlanTrackingType) => (
            <TrackingRow key={plan.id} samplePlan={plan} toggleInitSearch={toggleInitSearch} />
          )),
        )}
      </Table.Tbody>
    </Table>
  );
};

export default TrackingTable;
