import { Feature, FeatureCollection, LineString, MultiPolygon } from 'geojson';
import { SamplePlanType } from 'store/samplePlans/types';

export const RECEIVE_COLLECTIONS = 'RECEIVE_COLLECTIONS';
export const REQUEST_COLLECTIONS = 'REQUEST_COLLECTIONS';
export const COLLECTION_REQUEST_ERROR = 'COLLECTION_REQUEST_ERROR';

export type EOCollectionType = {
  created_at: string;
  field_id: number;
  id: number;
  name: string;
  overlap_percentage: number;
  sampling_plan_id: number;
  sampling_plan_name: string;
  scanning_time_minutes: number;
};

export type EOInferenceLayerType = {
  geojson_uri: string;
  id: number;
  layer_name: string;
  layer_type: string;
  layer_param: string;
  max_value: number | null;
  min_value: number | null;
};

export type EOCollectionFeatureType = Feature<LineString, EOCollectionType>;
export type EOCollectionFieldType = {
  acreage: number;
  acreage_unit: string;
  farm_name: string;
  id: number;
  name: string;
  operation: {
    id: number;
    name: string;
  };
  pending_eo_collections: EOCollectionFeatureType[];
  sampling_plans: SamplePlanType[];
};
export type EOCollectionFieldFeatureType = FeatureCollection<MultiPolygon, EOCollectionFieldType>;

export type EOCollectionStateType = {
  fieldCollections: EOCollectionFieldFeatureType[];
  hasFetched: boolean;
  isFetching: boolean;
  hasFailed: boolean;
};

type ReceiveEOCollectionsActionType = {
  type: typeof RECEIVE_COLLECTIONS;
  payload: {
    fields: EOCollectionFieldFeatureType[];
  };
};

type RequestEOCollectionsActionType = {
  type: typeof REQUEST_COLLECTIONS;
};

type EOCollectionErrorActionType = {
  type: typeof COLLECTION_REQUEST_ERROR;
};

export type EOCollectionActionType =
  | ReceiveEOCollectionsActionType
  | RequestEOCollectionsActionType
  | EOCollectionErrorActionType;

export type EOCollectionsResponseType = {
  fields: EOCollectionFieldFeatureType[];
};

export type CollectionConfirmationPayloadType = {
  assignments: {
    [samplingPlanId: number]: number[];
  };
};

export type CollectionsResponseType = {
  fields: [];
  first: number;
  last: number;
  page: number;
  per_page: number;
  next_num: number | null;
};
