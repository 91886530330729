import React from 'react';
import { AlertType, SamplePlanTrackingType } from 'store/samplePlans/types';
import { getString } from 'strings/translation';
import styles from './TrackingMap.module.css';
import { formatDateMonthDayYear } from 'util/date';
import { PARTIAL_STATUSES } from 'constants/samplePlanning';
import { hasNoProducts } from 'util/samplePlanTracking';

interface TrackingMapPopupProps {
  plan: SamplePlanTrackingType;
  openAssignSampler: (plan: SamplePlanTrackingType) => void;
  language: string;
}

const createTrackingMapPopup = ({ plan, openAssignSampler, language }: TrackingMapPopupProps) => {
  const isNoProducts = hasNoProducts(plan);

  return (
    <div>
      <div>
        <b>{getString('agency', language)}: </b>
        {plan.agency_name || getString('none', language)}
      </div>
      <div>
        <b>{getString('account', language)}: </b>
        {plan.operation_name}
      </div>
      <div>
        <b>{getString('field', language)}: </b>
        {plan.field_name}
      </div>
      <div>
        <b>{getString('acres', language)}: </b>
        {plan.calculated_area_acres}
      </div>
      <div>
        <b>{getString('samples', language)}: </b>
        {isNoProducts ? getString('none', language) : plan.sample_total_active_count}
      </div>
      <div>
        <b>{getString('status', language)}: </b>
        {plan.status}
      </div>
      {!isNoProducts && (
        <div>
          <b>{getString('sampler', language)}: </b>
          {plan.assigned_to_last_name
            ? `${plan.assigned_to_last_name}, ${plan.assigned_to_first_name}`
            : getString('none', language)}
        </div>
      )}
      {(plan.is_pro || plan.is_till_rx) && (
        <div>
          <b>{getString('scanner', language)}: </b>
          {plan.assigned_to_scan_last_name
            ? `${plan.assigned_to_scan_last_name}, ${plan.assigned_to_scan_first_name}`
            : getString('none', language)}
        </div>
      )}
      <div>
        <b>{getString('pro', language)}: </b>
        {plan.is_pro ? getString('yes', language) : getString('no', language)}
      </div>
      <div>
        <b>{getString('tillMapper', language)}: </b>
        {plan.is_till_rx ? getString('yes', language) : getString('no', language)}
      </div>
      <div>
        <b>{getString('updated', language)}: </b>
        {plan.updated_at ? formatDateMonthDayYear(plan.updated_at, language) : ''}
      </div>
      <div>
        <b>{getString('alerts', language)}: </b>
        {(plan.alerts || []).map((alert: AlertType) => getString(alert.name, language)).join('; ')}
      </div>
      {PARTIAL_STATUSES.includes(plan.status) && (
        <div className={styles.HoverOver} onClick={() => openAssignSampler(plan)}>
          <u>
            <b>
              {getString('assignSampler', language)} {'>'}
            </b>
          </u>
        </div>
      )}
      <div
        className={styles.HoverOver}
        onClick={() => window.open(`/orders/${plan.operation_id}`, '_blank')}
      >
        <u>
          <b>
            {getString('goToPlan', language)} {'>'}
          </b>
        </u>
      </div>
    </div>
  );
};

export default createTrackingMapPopup;
