import React, { useEffect, useState } from 'react';
import { Group, Slider } from '@mantine/core';
import { EOInferenceLayerType } from 'store/eoCollections/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';

type Props = {
  availableLayers: EOInferenceLayerType[];
  setGeojsonUri: React.Dispatch<React.SetStateAction<string>>;
  setInferenceLayer: (inferenceLayer: EOInferenceLayerType) => void;
  onChange: () => void;
};

const DEFAULT_DEPTH = 12;

const getLayerByDepth = (
  depth: number,
  layers: EOInferenceLayerType[],
): EOInferenceLayerType | undefined => {
  const matchedLayer = layers.find(({ layer_name }) => layer_name === `depth_${depth}_polys`);

  return matchedLayer;
};

export const CompactionTestResultsSlider = ({
  availableLayers,
  setGeojsonUri,
  setInferenceLayer,
  onChange,
}: Props) => {
  const [depth, setDepth] = useState(DEFAULT_DEPTH);
  const language = useBroswerLanguage();

  useEffect(() => {
    const matchedLayer = getLayerByDepth(depth, availableLayers);

    if (!matchedLayer) {
      return;
    }

    setGeojsonUri(matchedLayer.geojson_uri);
    setInferenceLayer(matchedLayer);
  }, [availableLayers, depth, setGeojsonUri, setInferenceLayer]);

  const handleChange = (newDepth: number) => {
    setDepth(newDepth);
    onChange();
  };

  return (
    <Group pr="lg" gap="xl">
      <b id="test-results-slider">{getString('depth', language)}</b>
      <Slider
        aria-labelledby="test-results-slider"
        min={0}
        max={18}
        value={depth}
        step={1}
        thumbSize={24}
        label={(inch) => `${inch}"`}
        style={{ flex: 1 }}
        marks={[
          { value: 0, label: getString('surface', language) },
          { value: 3, label: '3"' },
          { value: 6, label: '6"' },
          { value: 9, label: '9"' },
          { value: 12, label: '12"' },
          { value: 16, label: '16"' },
          { value: 18, label: '18"' },
        ]}
        onChange={handleChange}
      />
    </Group>
  );
};
