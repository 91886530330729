import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Group, Text, Stack, Center, Avatar } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Spinner } from 'common';
import { setLocalStorage } from 'util/localStorage';
import { PLAN_TRACKING_FILTERS, UNASSIGNED_EO } from 'constants/samplePlanning';
import { ALL_ALERTS, EO_DISPATCH, ONLY_ALERTS } from 'constants/alerts';
import styles from './Container.module.css';
import { SamplePlanTrackingType } from 'store/samplePlans/types';
import { requestSamplePlanTrackingPage } from 'store/samplePlans/requests';
import setToast from 'actions/toastActions';
import { FONT_WEIGHT_BOLDISH } from 'constants/mantine';

type AlertSummary = {
  value: number;
  alertIndex: number;
  name: string;
};

const AlertSummaryContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const [myPlans, setMyPlans] = useState<SamplePlanTrackingType[]>([]);
  const [eoPlans, setEOPlans] = useState<SamplePlanTrackingType[]>([]);

  const getMyPlans = async () => {
    try {
      const response = await requestSamplePlanTrackingPage(
        1,
        false,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        ONLY_ALERTS,
      );
      setMyPlans(response);
    } catch (e) {
      dispatch(setToast(e.message, 'error'));
    }
  };

  const getEOPlans = async () => {
    try {
      const response = await requestSamplePlanTrackingPage(
        1,
        true,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        UNASSIGNED_EO,
      );
      setEOPlans(response);
    } catch (e) {
      dispatch(setToast(e.message, 'error'));
    }
  };

  useEffect(() => {
    getMyPlans();
    getEOPlans();
  }, []);

  const { isFetchingTracking, alertNames } = useSelector((state: RootState) => ({
    isFetchingTracking: state.samplePlans.isFetchingTracking,
    alertNames: state.user.alertNames,
  }));

  const alertSummary: Record<string, AlertSummary> = useMemo(() => {
    const alertStrings = alertNames.reduce((acc, cur, index) => {
      if (cur.id !== ALL_ALERTS && cur.id !== ONLY_ALERTS) {
        return {
          ...acc,
          [cur.id]: {
            value: 0,
            alertIndex: index,
            name: cur.value,
          },
        };
      }
      return {};
    }, {});
    myPlans.forEach((plan) => {
      plan.alerts.forEach((a) => {
        if (alertStrings[a.name]) {
          alertStrings[a.name].value += 1;
        }
      });
    });
    return alertStrings;
  }, [myPlans, isFetchingTracking, alertNames]);

  const handleAlertClicked = (alertName) => {
    const newJson =
      alertName === EO_DISPATCH
        ? { assignment: UNASSIGNED_EO, showAllAccounts: true }
        : { alert: alertName, showAllAccounts: false };
    setLocalStorage(PLAN_TRACKING_FILTERS, JSON.stringify(newJson));
    navigate('/plan-tracking');
  };

  const AlertBox = ({ alertKey, alertValue }) => (
    <Stack
      justify="space-between"
      className={styles.AlertBox}
      onClick={() => handleAlertClicked(alertValue.name)}
    >
      <Center>
        <Text size="md" fw={500} className={styles.AlertString}>
          {getString(alertKey, language)}
        </Text>
      </Center>
      <Center>
        <Avatar color="blue" className={alertValue.value ? styles.Avatar : undefined} radius="xl">
          {String(alertValue.value)}
        </Avatar>
      </Center>
    </Stack>
  );

  return (
    <Stack justify="center" align="center">
      <div className={styles.Divider} />
      <Text size="xl" fw={FONT_WEIGHT_BOLDISH}>
        {getString('myAlerts', language)}
      </Text>
      {!Object.keys(alertSummary).length ? (
        <Center>
          <Spinner className={styles.SmallSpinner} />
        </Center>
      ) : (
        <Group justify="center">
          <AlertBox
            alertKey={EO_DISPATCH}
            alertValue={{ value: eoPlans.length, name: EO_DISPATCH }}
          />
          {Object.entries(alertSummary).map(([alertKey, alertValue]) => {
            return <AlertBox key={alertKey} alertKey={alertKey} alertValue={alertValue} />;
          })}
        </Group>
      )}
    </Stack>
  );
};

export default AlertSummaryContainer;
