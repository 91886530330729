import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Button, SegmentedControl } from '@mantine/core';
import turfBbox from '@turf/bbox';
import center from '@turf/center';
import { featureCollection } from '@turf/helpers';
import intersect from '@turf/intersect';
import mapboxgl, { GeoJSONSourceRaw, LngLatBoundsLike } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import useMapboxGl from 'common/MapHooks';
import { NUTRIENT_PANEL, PRESSURE_PANEL } from 'constants/products';
import { BCSR, MAP_ANALYTIC, SOIL_ATTRIBUTES } from 'constants/results';
import { AnalyticType, SingleAnalyticType } from 'store/analytics/types';
import { FieldType, MapboxSample, SamplingPlanType } from 'store/fields/types';
import { RecommendationType } from 'store/recommendations/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { WHITE } from 'util/mapImageryColors';
import { getFieldResultsConfig } from 'util/overviewResultsDisplay';
import { handleJsonResponse } from 'util/request';
import { setNonTillageProHoverHandlers, getProMapPaintFill } from 'util/proMaps';
import {
  adjustSamplesForInterpolation,
  getDisplaySamplesForAnalytic,
  getMidwestBenchmarkText,
} from 'util/results';
import { getProNutrientMapLayer } from 'util/samplePlan';

import Popup from 'common/Maps/Popup';
import createAnalyticPopup, {
  getProLayerPopupContent,
  getProNutrientPopupSettings,
} from '../common/MapPopup';

import styles from './Map.module.css';

interface MapProps {
  mapRef: { current: mapboxgl.Map | null };
  mapContainerRef: { current: HTMLDivElement | null };
  activeAnalytic: AnalyticType;
  field: FieldType;
  samples: MapboxSample[];
  samplingPlan: SamplingPlanType;
  midwestBenchmark: SingleAnalyticType;
  recommendations: RecommendationType[];
}

export interface ViewPortProps {
  centerLongitude?: number;
  centerLatitude?: number;
  latitude: number;
  longitude: number;
  zoom: number;
  width?: number;
  height?: number;
}

const Map = ({
  field,
  mapRef,
  mapContainerRef,
  samplingPlan,
  activeAnalytic,
  samples,
  midwestBenchmark,
  recommendations,
}: MapProps) => {
  const language = useBroswerLanguage();
  const [centerLongitude, centerLatitude] = center(field).geometry?.coordinates as number[];

  const proLayer = getProNutrientMapLayer(samplingPlan, activeAnalytic);

  const [mapVersion, setMapVersion] = useState(proLayer ? 'pro' : 'points');
  const mapPointsId = `${MAP_ANALYTIC}-${samplingPlan.field_id}-${samplingPlan.id}-${activeAnalytic.id}-points`;
  const mapProId = `${MAP_ANALYTIC}-${samplingPlan.field_id}-${samplingPlan.id}-${activeAnalytic.id}-pro`;
  const pointsQuantityId = `${mapPointsId}-quantity`;

  const [initialViewport, setInitialViewport] = useState({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
  });

  const [viewport, setViewport] = useState<ViewPortProps>({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
    width: 0,
    height: 0,
  });

  const [popupInfo, setPopupInfo] = useState<null | {
    content: React.ReactNode;
    lat: number;
    lng: number;
  }>(null);

  const [mapHasLoaded, setMapHasLoaded] = useState(false);
  const [samplingPlanId, setSamplingPlanId] = useState<number | null>(null);
  const [analyticId, setAnalyticId] = useState(activeAnalytic.id);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [trimmedFc, setTrimmedFc] = useState<ReturnType<typeof featureCollection> | null>();

  const trimLayer = async () => {
    if (proLayer) {
      try {
        const response = await handleJsonResponse(await fetch(proLayer.geojson_uri));
        const trimmed = featureCollection(
          response.features
            .map((f) => {
              const intersection = intersect(f, field.features[0], f.properties);
              if (!intersection) {
                return null;
              }
              return {
                ...intersection,
                properties: f.properties,
              };
            })
            .filter(Boolean),
        );
        setTrimmedFc(trimmed);
      } catch (error) {
        // eslint-disable-next-line
        console.error('Unable to trim geojson fs');
        // eslint-disable-next-line
        console.error(error);
      }
    }
  };

  useEffect(() => {
    trimLayer();
  }, [proLayer]);

  useMapboxGl(mapContainerRef, mapRef, null, viewport, setViewport, () => {}, true, null, null);

  const midwestBenchmarkRiskSummary = useMemo(
    () => getMidwestBenchmarkText(midwestBenchmark),
    [midwestBenchmark],
  );

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.on('load', () => {
        setMapHasLoaded(true);
      });
    }
  }, [mapRef, setMapHasLoaded]);

  const { planAnalytic } = getFieldResultsConfig(
    activeAnalytic,
    samplingPlan,
    samples,
    recommendations,
    field,
  );

  const resetMapLayers = () => {
    const map = mapRef.current;
    map?.getStyle().layers;
    if (mapHasLoaded && map) {
      [mapPointsId, mapProId, pointsQuantityId].forEach((layer) => {
        if (map.getLayer(layer)) {
          map.removeLayer(layer);
          map.removeSource(layer);
        }
      });
    }
  };

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      if (mapVersion === 'points') {
        setAnalyticId(activeAnalytic.id);
        const displaySamples = getDisplaySamplesForAnalytic(samples, activeAnalytic);
        const analyticSamples = samplingPlan.is_pro
          ? displaySamples.filter((sample) => {
              const panelToSearch = [BCSR, SOIL_ATTRIBUTES].includes(activeAnalytic.category)
                ? NUTRIENT_PANEL
                : PRESSURE_PANEL;
              return sample.properties.products.includes(panelToSearch);
            })
          : displaySamples;
        const newFeatureCollection = featureCollection(analyticSamples);
        const source = {
          type: 'geojson',
          data: newFeatureCollection,
        } as GeoJSONSourceRaw;
        const analysisAdjustedSamples = adjustSamplesForInterpolation(
          // @ts-ignore
          analyticSamples,
          activeAnalytic,
          samplingPlan,
        );
        const adjustedSource = {
          type: 'geojson',
          data: featureCollection(analysisAdjustedSamples),
        };
        const mapId = `${MAP_ANALYTIC}-${samplingPlan.field_id}-${samplingPlan.id}-${activeAnalytic.id}`;
        const removeMapId = `${MAP_ANALYTIC}-${samplingPlan.field_id}-${samplingPlanId}-${activeAnalytic.id}`;
        if (map.getLayer(removeMapId)) {
          map.removeLayer(removeMapId);
          map.removeSource(removeMapId);
          map.removeLayer(`${removeMapId}-quantity`);
          map.removeSource(`${removeMapId}-quantity`);
        }
        if (samples.length) {
          map.addLayer({
            id: mapId,
            type: 'fill',
            source,
            paint: {
              'fill-color': ['get', 'fill-color'],
              'fill-outline-color': WHITE,
            },
          });
          map.addLayer({
            id: `${mapId}-quantity`,
            type: 'symbol',
            // @ts-ignore
            source: adjustedSource,
            layout: {
              'text-field': ['get', 'quantity'],
              'text-allow-overlap': true,
              'text-justify': 'center',
              'text-size': 10,
            },
          });
        }
        map.on('click', mapId, (e) => {
          if (e.features?.[0]?.properties && mapRef.current && !map.getLayer(mapProId)) {
            const { id } = e.features[0].properties;
            const foundValidSample = samples.find((val) => val.properties.id === id);

            if (foundValidSample) {
              const content = createAnalyticPopup(
                foundValidSample,
                activeAnalytic,
                midwestBenchmarkRiskSummary,
                language,
              );

              setPopupInfo({ ...e.lngLat, content });
            }
          }
        });
      } else if (proLayer && planAnalytic?.data_summary) {
        if (!map.getLayer(mapProId)) {
          const source = {
            type: 'geojson',
            data: trimmedFc || proLayer.geojson_uri,
          } as GeoJSONSourceRaw;

          map.addSource(mapProId, source);

          map.addLayer({
            id: mapProId,
            type: 'fill',
            source: mapProId,
            paint: {
              'fill-color': getProMapPaintFill(activeAnalytic, planAnalytic.data_summary, proLayer),
              'fill-opacity': 1,
            },
          });

          map.on('click', mapProId, (evt) => {
            const { labelPrefix, layerName, units } = getProNutrientPopupSettings(
              activeAnalytic.id,
              proLayer,
              planAnalytic.unit,
            );

            const content = getProLayerPopupContent(
              map,
              evt,
              layerName,
              mapProId,
              labelPrefix,
              units,
            );

            setPopupInfo(content ? { ...evt.lngLat, content } : null);
          });

          setNonTillageProHoverHandlers(map, mapProId);
        }
      }

      map.getStyle();
      setSamplingPlanId(samplingPlan.id);
    }
  }, [
    mapHasLoaded,
    samples,
    analyticId,
    activeAnalytic,
    samplingPlan,
    mapRef,
    midwestBenchmarkRiskSummary,
    samplingPlanId,
    language,
    mapVersion,
    planAnalytic,
  ]);

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      const bbox = turfBbox(field) as LngLatBoundsLike;

      const source = { type: 'geojson', data: field } as GeoJSONSourceRaw;
      if (map.getLayer('field-outline')) {
        map.removeLayer('field-outline');
      }
      if (map.getSource('field-outline')) {
        map.removeSource('field-outline');
      }

      map.addLayer({
        id: `field-outline`,
        type: 'line',
        source,
        paint: { 'line-color': WHITE, 'line-width': 2 },
      });

      map.fitBounds(bbox, { duration: 0, padding: 30 });
      const zoom = map.getZoom();
      setViewport((prevViewport) => ({
        ...prevViewport,
        centerLatitude,
        centerLongitude,
        zoom,
      }));
      setInitialViewport((prevViewport) => ({
        ...prevViewport,
        latitude: centerLatitude,
        longitude: centerLongitude,
        zoom,
      }));
    }
  }, [centerLatitude, centerLongitude, field, mapHasLoaded, mapRef]);

  const recenterMap = () => {
    setViewport(initialViewport);
    if (mapRef.current) {
      mapRef.current.setZoom(initialViewport.zoom);
      mapRef.current.setCenter([initialViewport.longitude, initialViewport.latitude]);
    }
  };

  const toggleMap = (element: string) => {
    resetMapLayers();
    setMapVersion(element);
  };

  return (
    <Box className={styles.Wrapper}>
      <Box className={styles.MapWrapper} ref={wrapperRef}>
        <Box ref={mapContainerRef} className={styles.MapWrapper}>
          {popupInfo && mapRef.current && (
            <Popup
              {...popupInfo}
              map={mapRef.current}
              anchor="bottom"
              onClose={() => setPopupInfo(null)}
            >
              {popupInfo.content}
            </Popup>
          )}
        </Box>
        <Button variant="white" className={styles.Recenter} onClick={recenterMap}>
          {getString('recenter', language)}
        </Button>
        {proLayer ? (
          <SegmentedControl
            className={styles.Toggle}
            data={[
              { label: getString('proResults', language), value: 'pro' },
              { label: getString('pointResults', language), value: 'points' },
            ]}
            onChange={toggleMap}
            value={mapVersion}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default Map;
