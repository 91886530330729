import { Feature, MultiPolygon, Point, Polygon, FeatureCollection } from '@turf/helpers';
import { EOInferenceLayerType } from 'store/eoCollections/types';
import {
  handleJsonResponse,
  requestDelete,
  requestGet,
  requestPost,
  requestPostFile,
  requestPut,
  SERVICE_URL,
} from 'util/request';
import { queryStringify } from 'util/stringUtils';
import { AgronomicZonesType, SamplePlanType } from './types';

const BASE_URL = `${SERVICE_URL}/sampling_plan_v2/`;

export const getSamplePlans = async (id: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/sampling_plans/${id}`));

export const getSamplePlan = async (id: number) =>
  handleJsonResponse(await requestGet(`${BASE_URL}${id}`));

export const postSamplePlan = async (body: Partial<SamplePlanType>) =>
  handleJsonResponse(await requestPost(BASE_URL, { body }));

export const putSamplePlan = async (body: Partial<SamplePlanType>, id: number) =>
  handleJsonResponse(await requestPut(`${BASE_URL}${id}`, { body }));

export const deleteSamplePlan = async (id: number) =>
  handleJsonResponse(await requestDelete(`${BASE_URL}${id}`));

export const postZonesForUpload = async (file: File) =>
  handleJsonResponse(await requestPostFile(`${SERVICE_URL}/shapefile_zones/`, { file }));

export const snoozeAlert = async (id: number) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/snooze_sampling_plan_alert/${id}`));

export const dismissAlert = async (id: number) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/dismiss_sampling_plan_alert/${id}`));

export const getSamplePlanTillageLayers = async (
  id: number,
): Promise<{ layers: EOInferenceLayerType[] }> =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/tillage_layers/${id}`));

export const getAgronomicZones = async (
  id: number,
  numZones: number,
  zoneType: AgronomicZonesType,
) =>
  handleJsonResponse(
    await requestGet(
      `${SERVICE_URL}/agronomic_zones?field_id=${id}&num_zones=${numZones}&zone_type=${zoneType}`,
    ),
  );

export const postDensityGroupings = async (
  numGroups: number,
  featureCollection: FeatureCollection<Polygon | Point>,
) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/split_density_grouping/${numGroups}`, {
      body: featureCollection,
    }),
  );

export const postSplitZone = async (feature: Feature<Polygon | MultiPolygon>, centroids = true) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/split_zone/?centroids=${centroids}`, {
      body: feature,
    }),
  );

export const requestSamplePlanTrackingPage = async (
  page: number,
  all: boolean = true,
  operation_id?: number,
  agency_id?: number,
  assigned_to_id?: number,
  assigned_to_scan_id?: number,
  status?: string,
  assignment?: string,
  readiness?: string,
  alert?: string,
  pro_selection?: string,
  country?: string,
  map_bounds?: string,
  sort?: string,
  asc?: boolean,
) =>
  handleJsonResponse(
    await requestGet(
      `${SERVICE_URL}/sampling_plan_tracking/?${queryStringify({
        page,
        all,
        operation_id,
        agency_id,
        assigned_to_id,
        assigned_to_scan_id,
        status,
        assignment,
        readiness,
        alert,
        pro_selection,
        country,
        map_bounds,
        sort,
        asc,
      })}`,
    ),
  );

export const requestPutBulkAssignPlans = async (body: {
  user_id: number | null;
  scanner_id: number | null;
  plan_ids: number[];
  is_eo_sample_dispatch: boolean;
  is_eo_scan_dispatch: boolean;
}) => handleJsonResponse(await requestPut(`${SERVICE_URL}/bulk_assign_sample_plans/`, { body }));
