import React, { useState } from 'react';
import { Switch } from '@mantine/core';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';

type Props = {
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DcpPointsToggle = ({ onChange }: Props) => {
  const [isChecked, setIsChecked] = useState(false);
  const language = useBroswerLanguage();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.checked;

    onChange(value);
    setIsChecked(value);
  };

  return (
    <Switch
      checked={isChecked}
      label={getString('showDcpPoints', language)}
      onChange={handleChange}
    />
  );
};
