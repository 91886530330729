import { GeoJSONSource } from 'mapbox-gl';
import { useEffect, useState } from 'react';

import { EOInferenceLayerType } from 'store/eoCollections/types';
import { getSamplePlanTillageLayers } from 'store/samplePlans/requests';
import { dcpPointsLayerId, tillagePolygonLayerIds, polygonSourceIds } from 'constants/proMaps';
import { getRxFillColor, getTestResultsFillColor } from 'util/mapImageryColors';

type MapRef = React.MutableRefObject<mapboxgl.Map | null>;

export const useAvailableLayers = (samplingPlanId: number) => {
  const [layers, setLayers] = useState<EOInferenceLayerType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const availableLayers = await getSamplePlanTillageLayers(samplingPlanId);

      setLayers(availableLayers.layers);
    };

    fetchData();
  }, [samplingPlanId]);

  return layers;
};

export const useSetActiveLayerSourceData = (
  mapRef: MapRef,
  mapHasLoaded: boolean,
  activeLayerId: keyof typeof tillagePolygonLayerIds,
  testResultsGeojsonUri: string,
  rxUri: string,
): void => {
  useEffect(() => {
    if (!mapRef.current || !mapHasLoaded || !testResultsGeojsonUri) {
      return;
    }

    const source = mapRef.current.getSource(polygonSourceIds[activeLayerId]) as
      | GeoJSONSource
      | undefined;

    const data = activeLayerId === polygonSourceIds.testResults ? testResultsGeojsonUri : rxUri;

    if (source) {
      source.setData(data);
    } else {
      mapRef.current.addSource(polygonSourceIds[activeLayerId], {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });

      const newSource = mapRef.current.getSource(polygonSourceIds[activeLayerId]) as
        | GeoJSONSource
        | undefined;

      newSource?.setData(data);
    }
  }, [testResultsGeojsonUri, mapRef, mapHasLoaded, activeLayerId, rxUri]);
};

/**
 * Set the 'fill-color' paint property of the active layer based on the high, mid, and low values.
 *
 * @param activeLayerId layer id of currently active layer
 * @param mapHasLoaded map is loaded
 * @param map mapbox map instance
 * @param highMidLow object containing high, mid, and low values
 */
export const useSetCompactionFillColor = (
  activeLayerId: keyof typeof tillagePolygonLayerIds,
  mapHasLoaded: boolean,
  map: mapboxgl.Map | null,
  highMidLow?: {
    high: number;
    mid: number;
    low: number;
  },
) => {
  useEffect(() => {
    if (!mapHasLoaded || !map?.getLayer(activeLayerId)) {
      return;
    }

    if (activeLayerId === tillagePolygonLayerIds.rx) {
      map.setPaintProperty(activeLayerId, 'fill-color', getRxFillColor());
    } else if (activeLayerId === tillagePolygonLayerIds.testResults) {
      map.setPaintProperty(activeLayerId, 'fill-color', getTestResultsFillColor(highMidLow));
    }
  }, [activeLayerId, mapHasLoaded, map, highMidLow]);
};

export const useToggleDcpPointsVisibility = (
  mapRef: MapRef,
  mapHasLoaded: boolean,
  showDcpPoints: boolean,
): void => {
  useEffect(() => {
    if (!mapRef.current || !mapHasLoaded || !mapRef.current.getLayer(dcpPointsLayerId)) {
      return;
    }

    mapRef.current.setLayoutProperty(
      dcpPointsLayerId,
      'visibility',
      showDcpPoints ? 'visible' : 'none',
    );
  }, [showDcpPoints, mapRef.current, mapHasLoaded]);
};

export const useToggleActivePolyLayerVisibility = (
  mapRef: MapRef,
  mapHasLoaded: boolean,
  activeLayerId: keyof typeof tillagePolygonLayerIds,
): void => {
  useEffect(() => {
    if (!mapRef.current || !mapHasLoaded) {
      return;
    }

    const layer = mapRef.current.getLayer(activeLayerId);

    if (!layer) {
      return;
    }

    const inactiveLayerId =
      activeLayerId === tillagePolygonLayerIds.testResults
        ? tillagePolygonLayerIds.rx
        : tillagePolygonLayerIds.testResults;

    mapRef.current.setLayoutProperty(activeLayerId, 'visibility', 'visible');
    mapRef.current.setLayoutProperty(inactiveLayerId, 'visibility', 'none');
  }, [activeLayerId, mapRef.current, mapHasLoaded]);
};
