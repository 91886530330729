import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Center,
  Group,
  Loader,
  Select,
  Space,
  Stack,
  Text,
  LoadingOverlay,
} from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FiPrinter, FiArrowLeft, FiDownload } from 'react-icons/fi';
import { RootState } from 'store';
import { Header } from 'common';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getOperation, setActiveOperation } from 'store/operation/thunks';
import { getCropYearOptionsV2 } from 'util/results';
import { getOperationAllAgencies } from 'store/agencies/thunks';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import useOperationFieldGeometries from 'util/hooks/useOperationFieldGeometries';
import { CROP_PROTECTION } from 'constants/results';
import { getCropOrDefault, getCropsInFields } from 'util/cropPlans';

import CropContainer from './CropContainer';
import styles from './Container.module.css';
// import { OptimizePlans } from './OptimizePlans';
// import CropPlanSummary from '../CropPlanSummary';

type ParamsType = {
  operationId: string;
  analysis: string;
};

const CropPlanningFieldList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { operationId } = useParams<ParamsType>();

  const numericId = Number(operationId);
  const { search } = useLocation();
  const year = new URLSearchParams(search).get('year');
  const browserLang = useBroswerLanguage();
  const language = new URLSearchParams(search).get('language') || browserLang;
  const [selectedYear, setSelectedYear] = useState<string | null | undefined>(
    String(new Date().getFullYear()),
  );
  const [isShowSummary, toggleIsShowSummary] = useState<boolean>(false);

  const { operation, isOptimizingCropPlans } = useSelector((state: RootState) => ({
    operation: state.operations.operationsById[numericId],
    isOptimizingCropPlans: state.fieldGeometry.isOptimizingCropPlans,
  }));

  const yearOptions = useMemo(
    () => getCropYearOptionsV2(operation?.crop_years, language),
    [operation],
  );

  useEffect(() => {
    if (!year && operation?.crop_years?.length) {
      navigate(`/planning/${operationId}/${CROP_PROTECTION}?year=${operation.crop_years[0]}`);
    }
  }, [operation, year]);

  const fetchOperation = useCallback(
    () => dispatch(getOperation(numericId)),
    [dispatch, numericId],
  );

  const changeOperation = useCallback((id) => dispatch(setActiveOperation(id)), [dispatch]);

  useEffect(() => {
    if (!operation) {
      fetchOperation();
    } else {
      changeOperation(operation.id);
    }
  }, [fetchOperation, operation, changeOperation]);

  // for populating agency logo data when printing
  useEffect(() => {
    if (numericId) {
      dispatch(getOperationAllAgencies(numericId));
    }
  }, [dispatch, numericId]);

  const setCropYear = (cyear: string) => {
    setSelectedYear(cyear);
    navigate(`/planning/${operationId}/${CROP_PROTECTION}?year=${cyear}`);
  };

  const { allFieldGeometries, isFetchingList, willFetchList, hasFailedList } =
    useOperationFieldGeometries(operation, true, false);

  const operationFieldIds = operation.fields.map((field) => field.id);

  const fieldGeometryList = operationFieldIds
    .filter((id) => allFieldGeometries[id])
    .map((id) => allFieldGeometries[id]);

  const cropsInFields = getCropsInFields(fieldGeometryList);

  // TODO: Re-add with addition of bio and nutrient tabs
  // const analysisViewOptions = PLANNING_TABS.map((value) => ({
  //   value,
  //   label: getString(value, language),
  //   displayName: getString(value, language),
  // }));

  return operation?.name ? (
    <Stack>
      <LoadingOverlay
        visible={isOptimizingCropPlans}
        overlayProps={{ blur: 3 }}
        loaderProps={{
          children: (
            <Stack justify="center" align="center">
              <Text fw={600}>{getString('optimizingPlans', language)}...</Text>
              <Loader />
            </Stack>
          ),
        }}
      />
      <Header title={`${getString('planning', language)} - ${operation?.name || NOT_APPLICABLE}`}>
        {yearOptions.length > 0 && (
          <Select
            data-test-id="planning-year-dropdown"
            value={selectedYear}
            onChange={(val) => val && setCropYear(val)}
            data={yearOptions}
          />
        )}
        {/* TODO: smarter printing logic after field list is finished */}
        <Button onClick={window.print} variant="default">
          <FiPrinter />
        </Button>
      </Header>
      <Group
        className={styles.HideWhilePrinting}
        grow
        preventGrowOverflow={false}
        justify="space-between"
        wrap="nowrap"
      >
        <Group justify="space-between">
          <Group>
            {isShowSummary && (
              <Button
                variant="outline"
                onClick={() => toggleIsShowSummary(false)}
                leftSection={<FiArrowLeft />}
              >
                {getString('edit', language)}
              </Button>
            )}
            {/* <TabbedController
              options={analysisViewOptions}
              onChange={(idx: number) => {
                const newAnalysis = analysisViewOptions[idx].value;
                navigate(`/planning/${operationId}/${newAnalysis}?year=${selectedYear}`);
              }}
              activeIndex={analysisViewOptions.findIndex((s) => s.value === analysis)}
            /> */}
          </Group>
          {isShowSummary ? (
            <Button variant="outline" leftSection={<FiDownload />}>
              {getString('download', language)}
            </Button>
          ) : (
            <Group>
              {/* <OptimizePlans operationId={operation.id} fields={fieldGeometryList} /> */}
              {/* <Button variant="outline" onClick={() => toggleIsShowSummary(true)}>
                {getString('planSummary', language)}
              </Button> */}
            </Group>
          )}
        </Group>
      </Group>
      <Space h="md" />
      {/* {isShowSummary ? (
        <CropPlanSummary />
      ) : ( */}
      <>
        {cropsInFields.map((cropType) => (
          <CropContainer
            key={cropType}
            cropType={cropType}
            operation={operation}
            filteredFields={fieldGeometryList.filter(
              (field) => getCropOrDefault(field) === cropType,
            )}
            hasFailedList={hasFailedList}
            willFetchList={willFetchList}
            isFetchingList={isFetchingList}
          />
        ))}
      </>
      {/* )}} */}
    </Stack>
  ) : (
    <Center maw="100%" h={400}>
      <Loader />
    </Center>
  );
};

export default CropPlanningFieldList;
