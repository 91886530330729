export const ALL_ALERTS = 'all_alerts';
export const ONLY_ALERTS = 'only_alerts';

export const ALL_PLANS = 'all_plans';
export const ALL_PRO_OR_TILL = 'all_pro_or_till_rx';
export const PRO_ONLY = 'pro_only';
export const TILL_ONLY = 'till_rx_only';
export const NON_PRO_TILL = 'non_pro_till_rx';

export const EO_DISPATCH = 'eo_dispatch';
