import React, { useState } from 'react';
import { Group, SegmentedControl, Stack } from '@mantine/core';
import mapboxgl from 'mapbox-gl';

import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { CompactionTestResultsSlider } from './CompactionTestResultsSlider';
import { tillagePolygonLayerIds } from 'constants/proMaps';
import { DcpPointsToggle } from './DcpPointsToggle';
import {
  useAvailableLayers,
  useSetActiveLayerSourceData,
  useToggleActivePolyLayerVisibility,
  useToggleDcpPointsVisibility,
} from 'util/hooks/compactionMap';
import { RxResultsSlider } from './RxResultsSlider';
import { EOInferenceLayerType } from 'store/eoCollections/types';

export type TillageLayerId = (typeof tillagePolygonLayerIds)[keyof typeof tillagePolygonLayerIds];

type Props = {
  samplingPlanId: number;
  mapHasLoaded: boolean;
  mapRef: React.MutableRefObject<mapboxgl.Map | null>;
  dcpPointsEnabled?: boolean;
  setInferenceLayer: (inferenceLayer: EOInferenceLayerType) => void;
  onActiveLayerChange: (layerId: TillageLayerId) => void;
  onSliderChange: () => void;
  activeTillageLayerId: TillageLayerId;
  mapDisplayTypeToggle: React.ReactNode;
};

export const TillRxMapCtrls = ({
  samplingPlanId,
  mapHasLoaded,
  mapRef,
  dcpPointsEnabled,
  setInferenceLayer,
  onActiveLayerChange,
  onSliderChange,
  activeTillageLayerId,
  mapDisplayTypeToggle,
}: Props) => {
  const language = useBroswerLanguage();
  const availableLayers = useAvailableLayers(samplingPlanId);

  const [rxUri, setRxUri] = useState('');
  const [testResultsGeojsonUri, setTestResultsGeojsonUri] = useState('');
  const [showDcpPoints, setShowDcpPoints] = useState(false);
  const activeLayerIsTestResults = activeTillageLayerId === tillagePolygonLayerIds.testResults;
  const activeLayerIsRx = activeTillageLayerId === tillagePolygonLayerIds.rx;

  useSetActiveLayerSourceData(
    mapRef,
    mapHasLoaded,
    activeTillageLayerId,
    testResultsGeojsonUri,
    rxUri,
  );

  useToggleDcpPointsVisibility(mapRef, mapHasLoaded, showDcpPoints);
  useToggleActivePolyLayerVisibility(mapRef, mapHasLoaded, activeTillageLayerId);

  const ActiveLayerControl = (
    <SegmentedControl
      value={activeTillageLayerId}
      data={[
        { label: getString('testResults', language), value: tillagePolygonLayerIds.testResults },
        { label: getString('rx', language), value: tillagePolygonLayerIds.rx },
      ]}
      onChange={(value) => {
        onActiveLayerChange(value as TillageLayerId);
      }}
    />
  );

  return (
    <Stack gap="lg" mb="xs">
      <Group>
        {ActiveLayerControl}
        {dcpPointsEnabled && <DcpPointsToggle onChange={(checked) => setShowDcpPoints(checked)} />}
        {mapDisplayTypeToggle}
      </Group>
      {activeLayerIsTestResults && (
        <CompactionTestResultsSlider
          setGeojsonUri={setTestResultsGeojsonUri}
          availableLayers={availableLayers}
          setInferenceLayer={setInferenceLayer}
          onChange={onSliderChange}
        />
      )}
      {activeLayerIsRx && (
        <RxResultsSlider
          setGeojsonUri={setRxUri}
          availableLayers={availableLayers}
          setInferenceLayer={setInferenceLayer}
          onChange={onSliderChange}
        />
      )}
    </Stack>
  );
};
