import React from 'react';
import { Table } from '@mantine/core';
import { NUTRIENT_PANEL } from 'constants/products';
import { MapboxSample } from 'store/fields/types';
import { hasProducts } from 'util/product';
import { get590RowData } from 'util/590';
import { fiveNinetyColumnHeadings } from 'constants/590';

type Props = {
  samples?: MapboxSample[];
};

const { Td, Th, Tr, Thead, Tbody } = Table;

const NumericCell = ({ value }: { value: number | string }) => {
  return <Td align="right">{value}</Td>;
};

export const LabSampleTable = ({ samples = [] }: Props) => {
  const rows = samples
    .filter(
      ({ properties }) => properties.is_590_analysis && hasProducts(properties, NUTRIENT_PANEL),
    )
    .map(({ properties }) => {
      const rowData = get590RowData(properties);

      return (
        <Tr key={properties.id}>
          <Td>{properties.id}</Td>
          <NumericCell value={rowData.depth} />
          <NumericCell value={rowData.om} />
          <NumericCell value={rowData.nitrogen} />
          <NumericCell value={rowData.phosphorusM3} />
          <NumericCell value={rowData.phosphorusBray} />
          <NumericCell value={rowData.potassiumM3} />
          <NumericCell value={rowData.potassiumBray} />
          <NumericCell value={rowData.calcium} />
          <NumericCell value={rowData.magnesium} />
          <NumericCell value={rowData.ph} />
          <NumericCell value={rowData.sikoraBph} />
          <NumericCell value={rowData.cec} />
          <NumericCell value={rowData.boron} />
          <NumericCell value={rowData.copper} />
          <NumericCell value={rowData.iron} />
          <NumericCell value={rowData.manganese} />
          <NumericCell value={rowData.sulfur} />
          <NumericCell value={rowData.zinc} />
        </Tr>
      );
    });

  return (
    <Table>
      <Thead>
        <Tr>
          {fiveNinetyColumnHeadings.map(([heading, units]) => (
            <Th key={heading} ta="center">
              {heading}
              {units && <br />}
              {units}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>{rows}</Tbody>
    </Table>
  );
};
