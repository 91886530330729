export const tillagePolygonLayerIds = {
  /** Aka "Compaction" */
  testResults: 'testResults',
  rx: 'rx',
} as const;

export const polygonSourceIds = tillagePolygonLayerIds;
export const dcpPointsSourceId = 'dcpPoints';
export const dcpPointsLayerId = 'dcpPoints';

// GeoJSON property keys for symbology and popup click handling
export const compactionPropertyKeys = {
  testResults: 'psi',
  rx: 'till_to_inch',
};
