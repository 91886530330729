import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Center, Group, Loader, Space, Stack, Text } from '@mantine/core';
import { TabbedController } from 'common';
import { RootState } from 'store';
import showToast from 'actions/toastActions';
import { RecommendationSetType } from 'store/recommendationSets/types';
import { getRecommendationSet } from 'store/recommendationSets/thunks';
import { userIsSuperAdmin as userIsSuperAdminSelector } from 'store/user/selectors';
import SetAttributes from './Sections/SetAttributes';
import SetRecommendations from './Sections/SetRecommendations';

import styles from './Container.module.css';

const EditRecommendationContainer = () => {
  const [recSet, setRecSet] = useState<Partial<RecommendationSetType>>({
    name: '',
    id: 0,
    show_in_ui: true,
    recommendations: [],
  });
  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, toggleIsSaving] = useState(false);
  const dispatch = useDispatch();
  const { id: recSetID } = useParams<{
    id: string;
  }>();

  const numRecSetID = Number(recSetID);

  const { byId, isFetching, isSuperAdmin } = useSelector((state: RootState) => ({
    byId: state.recommendationSets.byId,
    isFetching: state.recommendationSets.isFetching,
    isSuperAdmin: userIsSuperAdminSelector(state),
  }));

  const fetchRecSet = useCallback(
    () => dispatch(getRecommendationSet(numRecSetID)),
    [dispatch, numRecSetID],
  );

  useEffect(() => {
    if (numRecSetID) {
      fetchRecSet();
    }
  }, [numRecSetID, fetchRecSet]);

  useEffect(() => {
    if (numRecSetID) {
      setRecSet(byId[numRecSetID]);
    }
    setIsLoading(isFetching);
  }, [numRecSetID, byId, isFetching]);

  const showMessage = useCallback(
    (message: string, type = '') => dispatch(showToast(message, type)),
    [dispatch],
  );

  const tabConfig = [
    {
      displayName: 'Set Attributes',
      id: 0,
    },
    isSuperAdmin
      ? {
          displayName: 'Product List',
          id: 1,
        }
      : null,
    {
      displayName: 'Default Recommendations',
      id: 2,
    },
  ].filter((tab) => tab !== null) as { displayName: string; id: number }[];

  return isLoading || isFetching ? (
    <Center h="60vh">
      <Loader />
    </Center>
  ) : (
    <div className={styles.Wrapper}>
      <Group>
        <Text size="xl" fw={500}>{`Recommendation Set details - ${recSet?.name}`}</Text>
      </Group>
      <Space h="md" />
      <TabbedController activeIndex={tabIndex} onChange={setTabIndex} options={tabConfig} />
      <Stack>
        {tabIndex === 0 && <SetAttributes recSet={recSet} />}
        {tabIndex === 1 && (
          <SetRecommendations
            recSet={recSet}
            onError={(msg: string) => showMessage(msg, 'error')}
            onSuccess={showMessage}
            isSaving={isSaving}
            toggleIsSaving={toggleIsSaving}
          />
        )}
      </Stack>
    </div>
  );
};

export default EditRecommendationContainer;
