import React from 'react';
import { Box, Text } from '@mantine/core';
import 'mapbox-gl/dist/mapbox-gl.css';
import { SingleAnalyticType } from 'store/analytics/types';
import { getString } from 'strings/translation';
import { convertCompactionRiskToScore } from 'util/proMaps';
import { FieldType } from 'store/fields/types';

type Props = {
  field: FieldType;
  language: string;
  planAnalytic?: SingleAnalyticType;
};

export const MapbookCompactionMeta = ({ field, planAnalytic, language }: Props) => {
  if (!planAnalytic) {
    return null;
  }

  const {
    risk_level,
    quantity,
    percent_compaction_above_300_psi,
    min_depth_300_psi,
    max_depth_300_psi,
  } = planAnalytic;

  const { acreage, acreage_unit } = field.features[0].properties;

  return (
    <Box
      display="grid"
      style={{
        columnGap: 'var(--mantine-spacing-md)',
        rowGap: 'var(--mantine-spacing-xs)',
        gridTemplateColumns: 'auto auto 1fr',
        gridTemplateRows: 'auto auto',
      }}
    >
      <Text>
        <b>{getString('score', language)}: </b>
        {convertCompactionRiskToScore(risk_level)}
      </Text>
      <Text>
        <b>{getString('average', language)}: </b>
        {Math.round(quantity)} {planAnalytic.unit}
      </Text>
      <Text>
        <b>% {getString('compaction', language)}: </b>
        {`${Math.round(acreage)}${acreage_unit} (${Math.round(percent_compaction_above_300_psi || 0)}%)`}
      </Text>
      <Text>
        <b>{getString('minDepth', language)}: </b>
        {(typeof min_depth_300_psi === 'number' && `${min_depth_300_psi}"`) || 'N/A'}
      </Text>
      <Text>
        <b>{getString('maxDepth', language)}: </b>
        {(typeof max_depth_300_psi === 'number' && `${max_depth_300_psi}"`) || 'N/A'}
      </Text>
    </Box>
  );
};
