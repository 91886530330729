import React from 'react';
import { Table } from '@mantine/core';
import { AgronomicProductType, PestGroupPayloadType } from 'store/cropPlans/types';
import { AnalyticType } from 'store/analytics/types';
import { CatalogType } from 'store/catalogs/types';
import { FOLIAR, IN_FURROW, SEED_TREATMENT } from 'constants/cropPlan';

import InputRows from './Common/ProductRows';

interface CropProtectionCatalogPropsType {
  products: AgronomicProductType[];
  analytics: AnalyticType[];
  pestGroups: PestGroupPayloadType;
  catalog: Partial<CatalogType>;
  setToastMessage: (message: string, type?: string, time?: number) => void;
}

const CropProtectionCatalog = ({
  products,
  analytics,
  catalog,
  pestGroups,
  setToastMessage,
}: CropProtectionCatalogPropsType) => {
  const makeRows = (
    agronomicProducts: AgronomicProductType[],
    category: string,
    label: string,
    mt?: string,
  ) => (
    <InputRows
      products={agronomicProducts}
      analytics={analytics}
      pestGroups={pestGroups}
      catalog={catalog}
      setToastMessage={setToastMessage}
      label={label}
      category={category}
      mt={mt}
    />
  );

  const foliarProducts = products.filter((product) => product.category === FOLIAR);
  const inFurrowProducts = products.filter((product) => product.category === IN_FURROW);
  const seedTreatments = products.filter((product) => product.category === SEED_TREATMENT);

  return (
    <Table stickyHeaderOffset={60}>
      {makeRows(seedTreatments, SEED_TREATMENT, 'seedTreatments')}
      {makeRows(inFurrowProducts, IN_FURROW, 'inFurrowProducts', '2rem')}
      {makeRows(foliarProducts, FOLIAR, 'foliarProducts', '2rem')}
    </Table>
  );
};

export default CropProtectionCatalog;
