export const fiveNinetyColumnHeadings: [heading: string, secondLine?: string][] = [
  ['ID'],
  ['Depth'],
  ['OM', '%'],
  ['N', 'ppm'],
  ['P-M3', 'ppm'],
  ['P-Bray', 'ppm'],
  ['K-M3', 'ppm'],
  ['K-Bray', 'ppm'],
  ['Ca-M3', 'ppm'],
  ['Mg-M3', 'ppm'],
  ['pH'],
  ['BpH', '(Sikora)'],
  ['CEC', 'meq/100'],
  ['B-M3', 'ppm'],
  ['Cu-M3', 'ppm'],
  ['Fe-M3', 'ppm'],
  ['Mn-M3', 'ppm'],
  ['S-M3', 'ppm'],
  ['Zn-M3', 'ppm'],
];
