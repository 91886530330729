import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  Button,
  Center,
  Title,
  Space,
  Divider,
  ComboboxItem,
  Select,
  Input,
  Group,
} from '@mantine/core';
import { AgronomicProductType, CombinedAnalyticType, SeedType } from 'store/cropPlans/types';
import { CatalogType } from 'store/catalogs/types';
import { requestCatalogAssignProduct, requestCatalogAssignSeed } from 'store/cropPlans/requests';
import { RootState } from 'store';
import { receiveSingleCatalog } from 'store/catalogs/actions';
import { AGRONOMIC_PRODUCTS, FOLIAR, IN_FURROW, SEEDS, SEED_TREATMENT } from 'constants/cropPlan';
import { ALL, CORN, SOYBEANS } from 'constants/variables';
import { getAllCatalogs } from 'store/catalogs/thunks';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { updateOperation } from 'store/operation/thunks';
import showToast from 'actions/toastActions';

import SeedProtection from '../SeedProtection';
import ProductProtection from '../ProductProtection';

type InputRatingLookup = { [id: number]: CombinedAnalyticType };

interface InputModalProps {
  closeModal: VoidFunction;
  defaultCategory?: string;
  isTemporary: boolean;
  modalOpened: boolean;
  catalog: Partial<CatalogType> | null;
  type: typeof AGRONOMIC_PRODUCTS | typeof SEEDS;
  data: Partial<AgronomicProductType> | Partial<SeedType>;
  operationId?: number;
}

const InputModal = ({
  closeModal,
  defaultCategory = '',
  isTemporary = false,
  modalOpened,
  catalog,
  type,
  data,
  operationId,
}: InputModalProps) => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState<typeof data>(data || {});

  const isSeed = type === SEEDS;
  const isProduct = type === AGRONOMIC_PRODUCTS;

  const categoryOptions = [
    {
      label: 'Foliar',
      value: FOLIAR,
    },
    {
      label: 'In Furrow',
      value: IN_FURROW,
    },
    {
      label: 'Seed Treatment',
      value: SEED_TREATMENT,
    },
  ];
  const [category, setCategory] = useState<ComboboxItem>(
    categoryOptions.find((option) => option.value === defaultCategory) || categoryOptions[0],
  );
  const cropOptions = [
    {
      label: 'All',
      value: ALL,
    },
    {
      label: 'Corn',
      value: CORN,
    },
    {
      label: 'Soybeans',
      value: SOYBEANS,
    },
  ];
  const [crop, setCrop] = useState<ComboboxItem>(
    cropOptions.find((option) => option.value === formData?.crop) || cropOptions[0],
  );

  const { analytics, pestGroups } = useSelector((state: RootState) => ({
    analytics: state.analytics.analytics,
    pestGroups: state.cropPlanning.pestGroups,
  }));

  const [coverageRatings, setCoverageRatings] = useState<InputRatingLookup>({});
  const [loading, setIsLoading] = useState(false);

  const getModalInputRatings = (input: typeof formData) => {
    return analytics.reduce((all: InputRatingLookup, analytic) => {
      const catalogInput = input.id ? catalog?.[isSeed ? 'seeds' : 'products']?.[input.id] : null;
      if (catalogInput) {
        const analyticInCoverage = catalogInput.ratings.find(
          (rating) => rating.analytic_id === analytic.id,
        );
        if (analyticInCoverage) {
          return { ...all, [analytic.id]: { ...analytic, ...analyticInCoverage } };
        }
      }
      // If no input is defined in catalog, use the default for the seed/product
      const analyticInCoverage = input.coverage_ratings?.find(
        (rating) => rating.analytic.id === analytic.id,
      );
      if (analyticInCoverage) {
        return { ...all, [analytic.id]: { ...analytic, ...analyticInCoverage } };
      }
      // Default to None coverage
      return { ...all, [analytic.id]: { ...analytic, coverage_rating: 0 } };
    }, {});
  };

  useEffect(() => {
    if (!Object.keys(coverageRatings).length) {
      setCoverageRatings(getModalInputRatings(data));
    }
  }, [type, data, coverageRatings, analytics]);

  if (!pestGroups) {
    return null;
  }

  const setInputRating = (input: typeof data, analyticId: number, coverage_rating: number) => {
    setCoverageRatings((ratings) => {
      const newRatings = { ...ratings };
      newRatings[analyticId] = { ...newRatings[analyticId], coverage_rating };
      return newRatings;
    });
  };

  const addInputTypeHelper = async () => {
    try {
      if (isSeed) {
        const seedValues = Object.values(coverageRatings).map((rating) => ({
          seed_id: formData.id,
          hybrid: (formData as SeedType).hybrid,
          crop: crop.value,
          category: category.value,
          analytic_id: rating.id,
          coverage_ratings: [Number(rating.coverage_rating)],
        }));
        const response = await requestCatalogAssignSeed(seedValues, catalog?.id, catalog?.name);
        return response;
      }
      if (isProduct) {
        const productValues = Object.values(coverageRatings).map((rating) => ({
          product_id: formData.id,
          name: (formData as AgronomicProductType).name,
          crop: crop.value,
          category: category.value,
          analytic_id: rating.id,
          coverage_ratings: [Number(rating.coverage_rating)],
        }));
        const response = await requestCatalogAssignProduct(
          productValues,
          catalog?.id,
          catalog?.name,
        );
        return response;
      }
    } catch (e) {
      throw e;
    }
  };

  const handleAddInput = async () => {
    try {
      setIsLoading(true);
      const isNewCatalog = catalog?.id === undefined;
      const response = await addInputTypeHelper();
      dispatch(
        showToast(
          `${getString(isSeed ? 'seed' : 'product', language)} ${getString(isTemporary ? 'added' : 'saved', language)}!`,
        ),
      );
      dispatch(receiveSingleCatalog(response));
      if (isNewCatalog) {
        if (operationId) {
          dispatch(updateOperation({ id: operationId, catalog_id: response.id }));
          dispatch(getAllCatalogs(operationId, true));
        } else {
          dispatch(getAllCatalogs());
          navigate(`/catalog/${response.id}`);
        }
      }
    } catch (e) {
      dispatch(showToast("Couldn't add product. Please refresh and try again.", 'error'));
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const handleOnValueChange = (
    attributeKey: string,
    newValue: string | string[] | { [key: string]: any },
  ) => {
    setFormData((form) => ({ ...form, [attributeKey]: newValue }));
  };

  const inputName = isSeed
    ? (formData as SeedType)?.hybrid
    : (formData as AgronomicProductType)?.name;
  const modalSize = isSeed ? '75%' : '85%';

  return (
    <Modal
      onClose={closeModal}
      opened={modalOpened}
      size={modalSize}
      title={
        <Group>
          {`${isTemporary ? 'Add' : 'Edit'} ${isProduct ? 'Product' : 'Seed'} - `}
          {formData.id ? (
            inputName
          ) : (
            <Input
              placeholder={getString('name', language)}
              value={inputName}
              onChange={(e) => handleOnValueChange(isSeed ? 'hybrid' : 'name', e.target.value)}
            />
          )}
        </Group>
      }
    >
      {isProduct && (
        <>
          <Select
            data={categoryOptions}
            disabled={Boolean(defaultCategory)}
            label="Category"
            value={category.value}
            onChange={(_value, option) => setCategory(option)}
          />
          <Space h="md" />
        </>
      )}
      {isProduct && (
        <>
          <Select
            data={cropOptions}
            label="Crop"
            value={crop.value}
            onChange={(_value, option) => setCrop(option)}
          />
          <Space h="md" />
        </>
      )}
      <Title order={6}>Coverage Ratings</Title>
      <Space h="md" />
      {isSeed && (
        <SeedProtection
          analytics={coverageRatings}
          pestGroups={pestGroups}
          catalog={catalog}
          seed={formData as SeedType}
          setInputRating={setInputRating}
          isTemporary
        />
      )}
      {isProduct && (
        <ProductProtection
          analytics={coverageRatings}
          pestGroups={pestGroups}
          product={formData as AgronomicProductType}
          catalog={catalog}
          setInputRating={setInputRating}
          isTemporary
        />
      )}
      <Space h="md" />
      <Divider />
      <Space h="md" />
      <Center>
        <Button disabled={loading || !inputName} onClick={handleAddInput}>
          Save
        </Button>
      </Center>
    </Modal>
  );
};

export default InputModal;
