import { getProLayerPopupContent } from 'apps/Results/common/MapPopup';
import { HIGH_RISK, LOW_RISK, MODERATE_RISK } from 'constants/fieldRisks';
import {
  bufferPhIds,
  BUFFER_PH_MAP_KEY,
  COMPACTION,
  COMPACTION_ID,
  PH_ID,
} from 'constants/results';
import {
  AnalyticType,
  AnalyticWithChartKeys,
  CompactionSearchKey,
  DataRangeType,
  SingleAnalyticType,
} from 'store/analytics/types';
import { SamplingPlanType } from 'store/fields/types';
import { getString } from 'strings/translation';
import {
  dcpPointsLayerId,
  dcpPointsSourceId,
  tillagePolygonLayerIds,
  polygonSourceIds,
  compactionPropertyKeys,
} from 'constants/proMaps';
import { COLOR_INTERPOLATION_OFFSET } from 'constants/mapbox';
import { Expression } from 'mapbox-gl';
import { EOInferenceLayerType } from 'store/eoCollections/types';
import { getRxFillColor, getTestResultsFillColor, RISK_FILL_COLORS } from './mapImageryColors';

const emptyFeatureCollection: GeoJSON.FeatureCollection = {
  type: 'FeatureCollection',
  features: [],
};

export const setTillageLayerClickHandlers = (
  map: mapboxgl.Map,
  setPopupInfo: (
    info: null | {
      lat: number;
      lng: number;
      content: React.ReactNode;
    },
  ) => void,
): void => {
  map.on('click', tillagePolygonLayerIds.testResults, (evt) => {
    const content = getProLayerPopupContent(
      map,
      evt,
      compactionPropertyKeys.testResults,
      tillagePolygonLayerIds.testResults,
      'Compaction',
      'psi',
    );

    setPopupInfo(content ? { ...evt.lngLat, content } : null);
  });

  map.on('click', tillagePolygonLayerIds.rx, (evt) => {
    const content = getProLayerPopupContent(
      map,
      evt,
      compactionPropertyKeys.rx,
      tillagePolygonLayerIds.rx,
      'Depth',
      'in',
    );

    setPopupInfo(content ? { ...evt.lngLat, content } : null);
  });
};

export const addCompactionSourcesAndLayers = (
  map: mapboxgl.Map,
  highMidLow?: { high: number; mid: number; low: number },
): void => {
  if (!map.getSource(polygonSourceIds.testResults)) {
    map.addSource(polygonSourceIds.testResults, {
      type: 'geojson',
      data: emptyFeatureCollection,
    });
  }

  if (!map.getSource(polygonSourceIds.rx)) {
    map.addSource(polygonSourceIds.rx, {
      type: 'geojson',
      data: emptyFeatureCollection,
    });
  }

  if (!map.getSource(dcpPointsSourceId)) {
    map.addSource(dcpPointsSourceId, {
      type: 'geojson',
      data: emptyFeatureCollection,
    });
  }

  if (!map.getLayer(tillagePolygonLayerIds.testResults)) {
    map.addLayer({
      id: tillagePolygonLayerIds.testResults,
      type: 'fill',
      source: polygonSourceIds.testResults,
      paint: {
        'fill-color': getTestResultsFillColor(highMidLow),
        'fill-opacity': 1,
      },
    });
  }

  if (!map.getLayer(tillagePolygonLayerIds.rx)) {
    map.addLayer({
      id: tillagePolygonLayerIds.rx,
      type: 'fill',
      source: polygonSourceIds.rx,
      paint: {
        'fill-color': getRxFillColor(),
        'fill-opacity': 1,
      },
      layout: {
        visibility: 'none',
      },
    });
  }

  if (!map.getLayer(dcpPointsLayerId)) {
    map.addLayer({
      id: dcpPointsLayerId,
      type: 'circle',
      source: dcpPointsSourceId,
      paint: {
        'circle-radius': 5,
        'circle-color': '#000',
      },
      layout: {
        visibility: 'none',
      },
    });
  }
};

export const convertCompactionRiskToScore = (risk: string) => {
  switch (risk) {
    case HIGH_RISK:
      return 3;
    case MODERATE_RISK:
      return 2;
    default:
      return 1;
  }
};

export const adjustAnalyticsForCompaction = (
  analytics: AnalyticType[],
  language: string,
): AnalyticWithChartKeys[] => {
  const compactionMetadataPairs = [
    [
      getString('percentCompacted', language),
      'percent_compaction_above_300_psi',
      getString('percentCompactedTooltip', language),
    ],
    ['PSI: 0-3"', 'average_compaction_0_3', ''],
    ['PSI: 4-6"', 'average_compaction_4_6', ''],
    ['PSI: 7-9"', 'average_compaction_7_9', ''],
    ['PSI: 10-12"', 'average_compaction_10_12', ''],
    ['PSI: 13-18"', 'average_compaction_13_18', ''],
  ];

  const baseAnalytic = {
    ...analytics[0],
    searchKey: 'base' as CompactionSearchKey,
    tooltipName: getString('averageCompactionTooltip', language),
  };

  const compactionMetaData = compactionMetadataPairs.map((pair, index) => ({
    ...baseAnalytic,
    name: pair[0],
    id: baseAnalytic.id + index + 1,
    searchKey: pair[1] as CompactionSearchKey,
    tooltipName: pair[2],
  }));

  return [compactionMetaData[0], baseAnalytic].concat(compactionMetaData.slice(1));
};

const PERCENT_RISK_DATA_SUMMARY = {
  low: [0, 25],
  moderate: [25, 75],
  high: [75, 100],
};

const getPercentCompationRisk = (compaction: number) => {
  if (compaction >= PERCENT_RISK_DATA_SUMMARY.high[0]) {
    return HIGH_RISK;
  }
  if (compaction >= PERCENT_RISK_DATA_SUMMARY.moderate[0]) {
    return MODERATE_RISK;
  }
  return LOW_RISK;
};

export const getAnalyticValueCompaction = (
  plan: SamplingPlanType | undefined | null,
  analytic: AnalyticWithChartKeys,
): SingleAnalyticType | null => {
  const compactionValue = plan?.analytics[COMPACTION]?.[COMPACTION_ID];
  if (!compactionValue || !analytic.searchKey) {
    return null;
  }

  if (analytic.searchKey === 'base') {
    return {
      ...compactionValue,
      quantity: Math.round(compactionValue.quantity),
    };
  }

  if (analytic.searchKey === 'percent_compaction_above_300_psi') {
    const quantity = Math.round(compactionValue.percent_compaction_above_300_psi || 0);
    return {
      ...compactionValue,
      quantity,
      risk_level: getPercentCompationRisk(quantity),
      data_summary: PERCENT_RISK_DATA_SUMMARY,
      unit: '%',
    };
  }

  const stratificationValue = compactionValue.compaction_stratification?.[analytic.searchKey];

  return {
    ...compactionValue,
    quantity: Math.round(stratificationValue?.quantity || 0),
    risk_level: (stratificationValue?.risk_level || LOW_RISK) as SingleAnalyticType['risk_level'],
  };
};

export const setTillageHoverHandlers = (map: mapboxgl.Map): void => {
  const tillageLayerIds = [tillagePolygonLayerIds.testResults, tillagePolygonLayerIds.rx];

  tillageLayerIds.forEach((id) => {
    map.on('mouseenter', id, () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseleave', id, () => {
      map.getCanvas().style.cursor = '';
    });
  });
};

export const setNonTillageProHoverHandlers = (map: mapboxgl.Map, layerId: string): void => {
  map.on('mouseenter', layerId, () => {
    map.getCanvas().style.cursor = 'pointer';
  });

  map.on('mouseleave', layerId, () => {
    map.getCanvas().style.cursor = '';
  });
};

/**
 * Split the Pro layer name into its first word before the underscore, e.g. for use in popup
 * prefixes or interpolation keys.
 */
export const getProLayerKey = (proLayer: EOInferenceLayerType): string =>
  proLayer.layer_name.split('_')[0];

export const getProMapPaintFill = (
  analytic: AnalyticType,
  data_summary: DataRangeType,
  proLayer: EOInferenceLayerType,
  sampleRange?: {
    highest: number;
    lowest: number;
  },
): Expression => {
  const splitKey = proLayer.layer_name.split('_')[0];

  if (analytic.id === PH_ID) {
    return [
      'interpolate',
      ['linear'],
      ['get', splitKey],
      data_summary.low[0],
      RISK_FILL_COLORS.HIGH_RISK,
      data_summary.low[1] - COLOR_INTERPOLATION_OFFSET,
      RISK_FILL_COLORS.MODERATE_RISK,
      (data_summary.moderate[0] + data_summary.moderate[1]) / 2,
      RISK_FILL_COLORS.LOW_RISK,
      data_summary.high[0] + COLOR_INTERPOLATION_OFFSET,
      RISK_FILL_COLORS.MODERATE_RISK,
      data_summary.high[1],
      RISK_FILL_COLORS.HIGH_RISK,
    ];
  }

  const low = sampleRange?.lowest ?? data_summary.low[0];
  const high = sampleRange?.highest ?? data_summary.high[1];
  const moderate = sampleRange
    ? (low + high) / 2
    : (data_summary.moderate[0] + data_summary.moderate[1]) / 2;

  return [
    'interpolate',
    ['linear'],
    ['get', bufferPhIds.includes(analytic.id) ? BUFFER_PH_MAP_KEY : splitKey],
    low,
    RISK_FILL_COLORS.HIGH_RISK,
    moderate,
    RISK_FILL_COLORS.MODERATE_RISK,
    high,
    RISK_FILL_COLORS.LOW_RISK,
  ];
};
