import {
  Map as MapboxMap,
  Popup as MapboxPopup,
  PopupOptions as MapboxPopupOptions,
} from 'mapbox-gl';
import * as React from 'react';
import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { deepEqual } from 'util/deepEqual';
import { PopupEvent, PopupInstance } from './types';

type PopupProps<OptionsT, PopupT extends PopupInstance> = OptionsT &
  Pick<MapboxPopupOptions, 'anchor' | 'offset' | 'className' | 'maxWidth'> & {
    /** Mapbox instance, typically the value of `mapRef.current` */
    map: MapboxMap;

    /** Longitude of the anchor location */
    lng: number;
    /** Latitude of the anchor location */
    lat: number;

    /** CSS style override, applied to the control's container */
    style?: React.CSSProperties;

    onOpen?: (e: PopupEvent<PopupT>) => void;
    onClose?: (e: PopupEvent<PopupT>) => void;
    children?: React.ReactNode;
  };

// Adapted from https://github.com/mapbox/mapbox-gl-js/blob/v1.13.0/src/ui/popup.js
const getClassList = (className?: string) => {
  return new Set(className ? className.trim().split(/\s+/) : []);
};

// This is a simplified version of
// https://github.com/facebook/react/blob/4131af3e4bf52f3a003537ec95a1655147c81270/src/renderers/dom/shared/CSSPropertyOperations.js#L62
const unitlessNumber = /box|flex|grid|column|lineHeight|fontWeight|opacity|order|tabSize|zIndex/;

const applyReactStyle = (element: HTMLElement, styles?: React.CSSProperties) => {
  if (!element || !styles) {
    return;
  }

  const { style } = element;

  for (const key in styles) {
    if (styles.hasOwnProperty(key)) {
      const value = styles[key];

      if (Number.isFinite(value) && !unitlessNumber.test(key)) {
        style[key] = `${value}px`;
      } else {
        style[key] = value;
      }
    }
  }
};

/* eslint-disable complexity,max-statements */
const Popup = <PopupOptions, PopupT extends PopupInstance>(
  props: PopupProps<PopupOptions, PopupT>,
  ref: React.Ref<PopupT>,
) => {
  const container = useMemo(() => {
    return document.createElement('div');
  }, []);

  const thisRef = useRef({ props });

  thisRef.current.props = props;

  const popup: PopupT = useMemo(() => {
    const pp = new MapboxPopup({
      anchor: props.anchor,
      offset: props.offset,
      className: props.className,
      maxWidth: props.maxWidth,
    }) as unknown as PopupT;

    pp.setLngLat([props.lng, props.lat]);

    pp.once('open', (e) => {
      thisRef.current.props.onOpen?.(e as PopupEvent<PopupT>);
    });
    return pp;
  }, [props.lat, props.lng]);

  useEffect(() => {
    const onClose = (e) => {
      thisRef.current.props.onClose?.(e as PopupEvent<PopupT>);
    };

    popup.on('close', onClose);
    popup.setDOMContent(container).addTo(props.map);

    return () => {
      // `onClose` should not be fired if the popup is removed by unmounting. When using React
      // strict mode, the component is mounted twice. Firing the `onClose` callback here would be a
      // false signal to remove the component. See https://github.com/visgl/react-map-gl/issues/1825
      popup.off('close', onClose);

      if (popup.isOpen()) {
        popup.remove();
      }
    };
  }, [popup]);

  useEffect(() => {
    applyReactStyle(popup.getElement(), props.style);
  }, [props.style]);

  useImperativeHandle(ref, () => popup, []);

  if (popup.isOpen()) {
    if (popup.getLngLat().lng !== props.lng || popup.getLngLat().lat !== props.lat) {
      popup.setLngLat([props.lng, props.lat]);
    }

    if (props.offset && !deepEqual(popup.options.offset, props.offset)) {
      popup.setOffset(props.offset);
    }

    if (popup.options.anchor !== props.anchor || popup.options.maxWidth !== props.maxWidth) {
      popup.options.anchor = props.anchor;
      popup.setMaxWidth(props.maxWidth);
    }

    if (popup.options.className !== props.className) {
      const prevClassList = getClassList(popup.options.className);
      const nextClassList = getClassList(props.className);

      prevClassList.forEach((c) => {
        if (!nextClassList.has(c)) {
          popup.removeClassName(c);
        }
      });

      nextClassList.forEach((c) => {
        if (!prevClassList.has(c)) {
          popup.addClassName(c);
        }
      });

      popup.options.className = props.className;
    }
  }

  return createPortal(props.children, container);
};

export default memo(forwardRef(Popup));
